<template>
  <section class="secondary--text">
    <v-row class="mb-4" v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
      <v-col>
        <v-btn text @click="cerrarVentana()"><v-icon>mdi-arrow-left</v-icon> Regresar a listado de procesos</v-btn>
      </v-col>
    </v-row>

    <!-- header -->
    <div class="d-flex justify-md-space-between flex-md-row flex-column">
      <div
        class="d-flex flex-column align-self-center text-md-start text-center my-md-0 my-8"
      >
        <span>
          {{ data_proceso_var?.codigo_interno }} |
          {{ data_proceso_var?.codigo_proceso }}
        </span>
        <span class="text-h6" v-text="data_proceso_var?.nombre_proceso" />
      </div>

      <div>
        <div class="d-flex flex-row justify-center">
          <div class="d-flex flex-column text-center">
            <span v-text="'Monto requerido'" />
            <span>
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(data_proceso_var?.monto_requerido)
              }}
            </span>
          </div>
          <v-divider class="mx-3" color="secondary" vertical />
          <div class="d-flex flex-column text-center">
            <span v-text="'Monto configurado'" />
            <span>
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(totalFinanmtCmp)
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- sub header -->
    <div class="my-8 text-center text-sm-left" v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
      <v-btn
        class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-0"
        color="secondary"
        dark
        @click="$refs.modal_cifrado.FetchCifradoFondoFtn(), ($refs.modal_cifrado.show_modal_var = true)"
      >
        <v-icon left> mdi-plus </v-icon>
        <span> Cifrado presupuestario </span>
      </v-btn>
    </div>

    <cifrado-component
      v-for="item in list_fondos_var"
      :key="item.id"
      :class="`my-12 ${item.doc_certificado ? '' : 'border-top--pending'}`"
      :fondo-prop="item"
      @refresh="FetchCifradoFinanciamientoFtn"
      :max-value-prop="data_proceso_var?.monto_requerido ?? null"
      :actual-value-prop="totalFinanmtCmp"
    />

    <modal-add-cifrado-component
      ref="modal_cifrado"
      @refresh="FetchCifradoFinanciamientoFtn"
      :max-value-prop="(data_proceso_var?.monto_requerido - totalFinanmtCmp) ?? null"
    />
  </section>
</template>

<script>
import CifradoComponent from "./components/CifradoComponent.vue";
import ModalAddCifradoComponent from "./components/ModalAddCifradoComponent.vue";

export default {
  components: {
    CifradoComponent,
    ModalAddCifradoComponent,
  },

  data: () => ({
    data_proceso_var: {},
    list_fondos_var: [],
  }),

  computed: {
    totalFinanmtCmp() {
      let total = 0;
      this.list_fondos_var.forEach((item) => {
        item.fup.forEach((fup) => {
          total += Number(fup.monto);
        });
      });
      return total;
    },
  },

  methods: {
    async FetchCifradoFinanciamientoFtn() {
      const { data, status } =
        await this.services.Cifrados.getFinanciamientoCifrado(
          Number(this.$route.params.idProceso)
        );

      if (status === 200) {
        this.list_fondos_var = data;
      }
    },

    async FetchProcesoFtn() {
      const { data, status } = await this.services.Cifrados.getDetailProceso(
        Number(this.$route.params.idProceso)
      );

      if (status === 200) {
        this.data_proceso_var = data;
      }
    },

    cerrarVentana() {
      window.close()
    },
  },

  async created() {

    await this.FetchCifradoFinanciamientoFtn();
    await this.FetchProcesoFtn();

  },
};
</script>

<style>
.border-top--pending {
  border-top: 3px solid #f44336 !important;
}
</style>