<template>
  <v-dialog v-model="show_modal_var" persistent max-width="500">
    <v-card>
      <div class="headerIcon d-flex justify-end">
        <v-icon class="secondary--text" @click="CloseModalFtn">
          mdi-close
        </v-icon>
      </div>
      <v-card-text>
        <!-- registrar fondo -->
        <v-row class="secondary--text">
          <v-col cols="12">
            <span class="text-h6"> Agregar financiamiento por unidad </span>
          </v-col>
          <template v-if="new_item_var">
            <v-col cols="12" sm="6">
              <v-autocomplete
                label="Unidad *"
                outlined
                :items="ctl_unidades_financiamiento_var"
                item-text="nombre_unidad"
                return-object
                v-model="select_unidad_var"
                @blur="$v.select_unidad_var.$touch()"
                :error-messages="UnidadErrors"
                hide-details
              />
              <p v-if="select_unidad_var" class="mt-2">Monto disponible: $ {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(select_unidad_var?.monto_disponible)
              }}</p>
            </v-col>
            <v-col cols="12" sm="6">
              <money-text-field-component
                :disabled="!select_unidad_var"
                ref="monto_finanmt"
                label="Financiamiento($) *"
                v-model="monto_finanmt_var"
                @blur="$v.monto_finanmt_var.$touch()"
                :error-messages="MontoErrors"
                :max-value="Number(select_unidad_var?.monto_disponible) ?? null"
                hide-details
              />
            </v-col>
          </template>
          <template v-if="!new_item_var && valueProp">
            <v-col cols="12" sm="6" class="d-flex flex-column justify-start">
              <span v-text="'Unidad'" />
              <span class="text-h7">
                {{ valueProp.FinanciamientoUnidad.Unidad.nombre }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex flex-column text-right">
              <span v-text="'Monto anterior'" />
              <span class="text-h7">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    style: "currency",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  }).format(valueProp.monto)
                }}
              </span>
            </v-col>
            <v-col cols="12">
              <money-text-field-component
                ref="new_monto"
                label="Nuevo monto *"
                v-model="new_monto_var"
                :showAlerts="false"
                :max-value="Number(valueProp.FinanciamientoUnidad.monto_disponible)"
              />
              <p class="mt-2 mb-0">
                Financiamiento disponible: {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(Number(valueProp.FinanciamientoUnidad.monto_disponible))
              }}
              </p>
            </v-col>
          </template>
        </v-row>
        <!-- acciones -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mb-4 justify-end">
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="CloseModalFtn"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            :disabled="ValidSave"
            @click="HandlerSaveFtn"
          >
            Agregar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DataTableComponent,
    MoneyTextFieldComponent,
  },

  validations: {
    select_unidad_var: {
      required,
    },
    monto_finanmt_var: {
      required,
    },
  },

  props: {
    valueProp: {
      type: Object,
      default: null,
    },
    fondoProp: {
      type: Object,
      required: true,
    },
    maxValueProp: {
      type: Number | null,
      required: true,
    },
    actualValueProp: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    show_modal_var: false,
    new_monto_var: null,
    new_item_var: true,
    HEADERS_CONST: [
      { text: "Unidad", value: "unidad" },
      { text: "Financiamiento($)", value: "financiamiento" },
      { text: "Acciones", value: "actions" },
    ],
    ctl_unidades_financiamiento_var: [],
    select_unidad_var: null,
    monto_finanmt_var: null,
  }),

  computed: {
    UnidadErrors() {
      const errors = [];
      if (!this.$v.select_unidad_var.$dirty) return errors;
      !this.$v.select_unidad_var.required && errors.push("Unidad requerida");
      return errors;
    },

    MontoErrors() {
      const errors = [];
      if (!this.$v.monto_finanmt_var.$dirty) return errors;
      !this.$v.monto_finanmt_var.required &&
        errors.push("Financiamiento requerido");
      return errors;
    },

    ValidSave() {
      if (this.new_item_var) {
        return this.$v.$invalid;
      } else {
        return !this.new_monto_var;
      }
    },
  },

  methods: {
    CloseModalFtn() {
      this.show_modal_var = false;
      this.new_item_var = true;
      this.monto_finanmt_var = null;
      this.$refs.monto_finanmt?.updateValue('');

      if (this.new_item_var && this.valueProp != null) {
        this.new_monto_var = null;
      } else {
        this.select_unidad_var = null;
      }
    },

    async FetchCtlUnidadesFtn() {

      const { data, status } =
        await this.services.Cifrados.getUnidadFinanciamiento(
          this.$route.params.idProceso,
          this.fondoProp.id_fondo
        );
      if (status === 200) {
        this.ctl_unidades_financiamiento_var = data;
      }

    },

    async HandlerSaveFtn() {
      let reprent = 0;
      if (this.new_item_var) {
        reprent = Number(this.monto_finanmt_var) + Number(this.actualValueProp);
        if (reprent > this.maxValueProp) {
          this.temporalAlert({
            message: "El monto ingresado supera el monto total del fondo",
            show: true,
            type: "error",
          });
          return;
        }


        const { data, status } =
          await this.services.Cifrados.postFinanciamientoCifrado({
            id_fondo_proceso_certificado: this.fondoProp.id,
            id_financiamiento_unidad:
              this.select_unidad_var.id_financiamiento_unidad,
            monto: Number(this.monto_finanmt_var),
          });


        if (status === 201) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
          this.CloseModalFtn();
          this.$emit("update");
        }
      } else {
        reprent = Number(this.new_monto_var) + (this.actualValueProp - Number(this.valueProp.monto));

        if (reprent > this.maxValueProp) {
          this.temporalAlert({
            message: "El monto ingresado supera el monto requerido para el proceso de compra.",
            show: true,
            type: "error",
          });
          return;
        }


        const { data, status } =
          await this.services.Cifrados.putFinanciamientoCifrado(
            this.valueProp.id,
            {
              monto: Number(this.new_monto_var),
            }
          );


        if (status === 200) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
          this.CloseModalFtn();
          this.$emit("update");
        }
      }
    },
  },
};
</script>

<style>
</style>