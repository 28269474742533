<template>
  <v-dialog v-model="show_modal_var" persistent max-width="1000">
    <v-card>
      <div class="headerIcon d-flex justify-end">
        <v-icon class="secondary--text" @click="CloseModalFtn">
          mdi-close
        </v-icon>
      </div>
      <v-card-text>
        <!-- registrar fondo -->
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span class="text-h6 secondary--text">
              Agregar cifrado presupuestario
            </span>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              label="Cifrado"
              outlined
              v-model="select_cifrado_var"
              item-text="fondo.cifrado_presupuestario"
              return-object
              :items="ctl_fondos_list"
              :readonly="select_cifrado_var != null"
            />
          </v-col>
          <template v-if="select_cifrado_var">
            <v-col cols="12">
              <span class="text-h6 secondary--text">
                Agregar financiamiento por unidad
              </span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                label="Unidad *"
                v-model="select_unidad_var"
                outlined
                :items="ctl_unidad_var"
                item-text="nombre"
                return-object
                clearable
                hide-details
              />
              <p v-if="select_unidad_var" class="mt-2">Monto disponible: $ {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(select_unidad_var?.monto_disponible)
              }}</p>
            </v-col>
            <v-col cols="12" sm="5">
              <money-text-field-component
                ref="ref_monto"
                label="Financiamiento *"
                v-model="monto_var"
                :disabled="select_unidad_var == null"
                :max-value="Number(select_unidad_var?.monto_disponible)"
                :show-alerts="false"
              />
            </v-col>
            <v-col cols="12" sm="1">
              <v-btn
                color="secondary"
                dark
                class="py-6"
                @click="AddFinanciamientoFtn"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <data-table-component
                :headers="HEADERS_CONST"
                :items="list_financiamientos_var"
                inhabilitar_paginacion
                :tiene_paginacion="false"
              >
                <template v-slot:[`item.financiamiento`]="{ item }">
                  {{
                    Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    }).format(item.financiamiento)
                  }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="DeleteFinanciamientoFtn(item)"
                      >
                        <v-icon color="secondary">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span> Eliminar </span>
                  </v-tooltip>
                </template>
              </data-table-component>

              <h6 class="text-h6 secondary--text text-end mt-4 mb-0">Total del financiamiento: $ {{
                    Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    }).format(this.suma_financiamientos_var)
                  }}</h6>
            </v-col>
          </template>
        </v-row>
        <!-- acciones -->
        <v-row
          cols="12"
          class="d-flex flex-column flex-sm-row mx-sm-4 mb-6 mt-6 justify-end"
        >
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="CloseModalFtn"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="SaveFtn"
            :disabled="list_financiamientos_var.length == 0"
          >
            Agregar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";

export default {
  components: {
    DataTableComponent,
    MoneyTextFieldComponent,
  },

  props: {
    maxValueProp: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    show_modal_var: false,
    select_cifrado_var: null,
    select_unidad_var: null,
    monto_var: null,
    HEADERS_CONST: [
      { text: "Unidad", value: "unidad" },
      { text: "Financiamiento($)", value: "financiamiento", align: "right" },
      { text: "Acciones", value: "actions", align: "center", width: "25%" },
    ],
    ctl_fondos_list: [],
    ctl_unidad_var: [],

    list_financiamientos_var: [],
    suma_financiamientos_var: 0,
  }),

  methods: {
    CloseModalFtn() {
      this.show_modal_var = false;
      this.select_cifrado_var = null;
      this.select_unidad_var = null;
      this.list_financiamientos_var = [];
    },

    AddFinanciamientoFtn() {
      if (this.select_unidad_var && this.monto_var) {
        const data = {
          id: this.select_unidad_var.id_financiamiento_unidad,
          unidad: this.select_unidad_var.nombre,
          financiamiento: this.monto_var,
        };

        // verificar si ya existe en el array
        const exist = this.list_financiamientos_var.find(
          (item) => item.id === this.select_unidad_var.id
        );
        if (exist) {
          this.temporalAlert({
            show: true,
            message: "Unidad ya agregada.",
            type: "warning",
          });
          return;
        }

        this.list_financiamientos_var.push(data);
        this.suma_financiamientos_var += Number(this.monto_var);
        this.select_unidad_var = null;
      }
    },

    DeleteFinanciamientoFtn(item) {
      const index = this.list_financiamientos_var.findIndex(
        (element) => element.id === item.id
      );
      this.suma_financiamientos_var -= Number(item.financiamiento);
      this.list_financiamientos_var.splice(index, 1);
    },

    async FetchCifradoFondoFtn() {

      const { data, status } = await this.services.Cifrados.getFondosCifrado(
        Number(this.$route.params.idProceso)
      );

      if (status === 200) {
        this.ctl_fondos_list = data;
      }


    },

    async SaveFtn() {
      if (this.list_financiamientos_var.length > 0) {
        const sum = this.list_financiamientos_var.reduce(
          (acc, item) => acc + Number(item.financiamiento),
          0
        );

        if (Number(sum) > Number(this.maxValueProp) + 0.1) {
          this.temporalAlert({
            show: true,
            message:
              "El monto total a financiar no puede ser mayor al monto requerido por el proceso de compra.",
            type: "warning",
          });
          return;
        }

        const payload = {
          id_proceso_compra: Number(this.$route.params.idProceso),
          id_fondo: this.select_cifrado_var.fondo.id,

          financiamientos: this.list_financiamientos_var.map((item) => ({
            id_financiamiento_unidad: item.id,
            monto: Number(item.financiamiento),
          })),
        };


        const { status } =
          await this.services.Cifrados.postCifrado(payload);


        if (status === 201) {
          this.temporalAlert({
            show: true,
            message: "Financiamiento agregado correctamente.",
            type: "success",
          });
          this.CloseModalFtn();
          this.$emit("refresh");
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "Debe agregar al menos un financiamiento.",
          type: "warning",
        });
      }
    },
  },

  watch: {
    select_cifrado_var(value) {
      if (value) {
        this.ctl_unidad_var = value.financiamientos;
      }
    },
    select_unidad_var(value) {
      if (!value) {
        this.financiamiento_var = null;
        this.$refs.ref_monto.clear();
      }
    },
  },
};
</script>
