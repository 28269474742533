<template>
  <v-card elevation="4">
    <div class="pa-5">
      <div class="d-flex justify-space-between secondary--text">
        <p>{{ fondoProp.Fondo.cifrado_presupuestario }} - {{ fondoProp.Fondo.tipo_fondo === 1 ? 'Proyecto' : 'Votado' }}</p>
        <p>
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              style: "currency",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(totalMontoCmp)
          }}
        </p>
      </div>
      <div class="d-flex justify-space-between my-4">
        <v-file-input v-if="activateBtnFtn" accept="application/pdf" prepend-icon="" prepend-inner-icon="mdi-paperclip"
          label="Certificado de fondos" outlined hide-details v-model="file_certificado_var" />
        <v-btn v-else class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-0" color="secondary" dark
          @click="DownloadFileFtn">
          <v-icon left> mdi-download </v-icon>
          <span> Descargar CDF </span>
        </v-btn>
        <v-btn dark color="secondary" class="py-7 ml-4" v-if="activateBtnFtn" :disabled="file_certificado_var == null"
          @click="UploadCertificadoFtn">
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
        <v-btn text color="secondary" dark class="ml-4" v-else-if="!activateBtnFtn && haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])"
          @click="edit_file_var = true">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-0" color="secondary" dark v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])"
          @click="$refs.modal_finanmt.FetchCtlUnidadesFtn(), ($refs.modal_finanmt.show_modal_var = true)">
          <v-icon left> mdi-plus </v-icon>
          <span> Financiamiento unidad </span>
        </v-btn>
      </div>
      <div>
        <data-table-component :headers="HEADERS_CONST" :items="fondoProp.fup" inhabilitar_paginacion
          :tiene_paginacion="false">
          <template v-slot:[`item.monto`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto)
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="OpenModalAddFtn(item)">
                  <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> Editar </span>
            </v-tooltip>
            <v-tooltip bottom v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="(selected_financiamiento = item), (confirmDelete = true)">
                  <v-icon color="secondary">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </div>
    </div>
    <modal-add-finanmt-component ref="modal_finanmt" :value-prop="select_item_var" :fondo-prop="fondoProp"
      @update="RefreshDataFtn" :max-value-prop="maxValueProp" :actual-value-prop="actualValueProp" />

    <ConfirmationDialogComponent
      :show="confirmDelete"
      btnConfirmar="Eliminar financiamiento"
      title="¿Desea eliminar el financiamiento seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="(confirmDelete = false), (selected_financiamiento = null)"
      @confirm="DeleteFinanmtFtn()"
    />
  </v-card>
</template>

<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import ModalAddFinanmtComponent from "./ModalAddFinanmtComponent.vue";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

export default {
  components: {
    DataTableComponent,
    ModalAddFinanmtComponent,
    ConfirmationDialogComponent,
  },

  props: {
    fondoProp: {
      type: Object,
      required: true,
    },
    maxValueProp: {
      type: Number | null,
      required: true,
    },
    actualValueProp: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    select_item_var: null,
    file_certificado_var: null,
    edit_file_var: false,
    HEADERS_CONST: [
      {
        text: "Unidad",
        value: "FinanciamientoUnidad.Unidad.nombre",
      },
      { text: "Financiamiento ($)", value: "monto", align: "right" },
      { text: "Acciones", value: "actions", sortable: false, align: "center", width: "25%" },
    ],
    confirmDelete: null,
    selected_financiamiento: null,
  }),

  computed: {
    activateBtnFtn() {
      return (this.edit_file_var || this.fondoProp.doc_certificado == null) && this.haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO']);
    },
    totalMontoCmp() {
      return this.fondoProp.fup.reduce(
        (acc, item) => acc + Number(item.monto),
        0
      );
    },
  },

  methods: {
    OpenModalAddFtn(item) {
      this.$refs.modal_finanmt.show_modal_var = true;
      this.$refs.modal_finanmt.new_item_var = false;
      this.select_item_var = item;
    },

    async DeleteFinanmtFtn() {

      const { data, status } =
        await this.services.Cifrados.deleteFinanciamientoCifrado(this.selected_financiamiento.id);


      if (status === 200) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
        this.$emit("refresh");
        this.selected_financiamiento = null;
        this.confirmDelete = false;
      }
    },

    async UploadCertificadoFtn() {

      const formData = new FormData();
      formData.append("certificado", this.file_certificado_var);
      formData.append("monto_total_certificacion", this.totalMontoCmp);

      const { data, status } =
        await this.services.Cifrados.uploadCertificadoFondo(
          this.fondoProp.id,
          formData
        );


      if (status === 201) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
        this.edit_file_var = false;
        this.file_certificado_var = null;
        this.$emit("refresh");
      }
    },

    async DownloadFileFtn() {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.fondoProp.doc_certificado,
          disk: "procesos",
        });


      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const file = new File([blob], "documento_cdf", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    },

    async RefreshDataFtn() {
      this.edit_file_var = true;
      this.$emit("refresh");
    },
  },

  created() {
    this.edit_file_var = this.fondoProp.doc_certificado ? false : true;
  },
};
</script>